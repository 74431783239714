import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';

const SatsTerminal: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className="fixed bottom-24 right-4 z-50">
      <div className={`transition-all duration-300 ${isCollapsed ? 'h-12' : 'h-[650px]'}`}>
        <div className="terminal-window w-[400px] rounded-lg overflow-hidden">
          <div 
            className="p-2 bg-black border-b border-green-500/30 flex justify-between items-center cursor-pointer hover:bg-green-500/10"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <span className="terminal-text">SatsTerminal</span>
            {isCollapsed ? (
              <ChevronUp size={20} className="text-green-400" />
            ) : (
              <ChevronDown size={20} className="text-green-400" />
            )}
          </div>
          <div className={`transition-all duration-300 ${isCollapsed ? 'h-0' : 'h-[650px]'}`}>
            <iframe 
              src="https://beta.satsterminal.com/en?embed=true&themeId=298&runeName=RUNNING•HAL•AI" 
              width="400" 
              height="650" 
              frameBorder="0"
              className={`transition-opacity duration-300 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SatsTerminal; 