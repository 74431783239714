import React, { useState, useEffect, useRef } from 'react';
import { useBadgeStore } from '../store/badgeStore';
import { Share2 } from 'lucide-react';

const GAME_WIDTH = 800; // Game width in pixels
const INITIAL_SPEED = 5; // Initial obstacle speed
const SPEED_INCREMENT = 0.2; // Increased for more noticeable progression
const INITIAL_DIFFICULTY = 0.02; // Starting difficulty
const DIFFICULTY_INCREMENT = 0.001; // Larger increment for more noticeable progression
const MIN_OBSTACLE_SPACING = 500; // Minimum pixels between obstacles
const SCORE_INCREMENT_THRESHOLD = 200; // More frequent difficulty increases
const PLAYER_CHAR = '🏃‍♂️';
const OBSTACLE_CHAR = '🌵';
const JUMP_HEIGHT = 100; // Increased jump height
const JUMP_DURATION = 400; // Reduced from 600 to make jump snappier
const COLLISION_THRESHOLD = 30; // Smaller collision box

const POWERUP_TYPES = {
  BITCOIN: '₿', // Temporary invincibility
  LIGHTNING: '⚡', // Speed boost
  BLOCK: '◻️', // Score multiplier
} as const;

const POWERUP_DESCRIPTIONS = {
  BITCOIN: "Temporary invincibility! 🛡️",
  LIGHTNING: "Speed boost activated! ⚡",
  BLOCK: "Double score multiplier! 2️⃣",
} as const;

const POWERUP_DURATIONS = {
  BITCOIN: 5000,
  LIGHTNING: 3000,
  BLOCK: 4000,
} as const;

const SIGHTSEEING_SPOTS = [
  { 
    score: 1000, 
    location: "Hal's Computer Museum",
    fact: "Here's where Hal ran the first Bitcoin node in 2009! 🖥️" 
  },
  { 
    score: 2000, 
    location: "RPoW Laboratory",
    fact: "Hal developed Reusable Proof of Work here in 2004! 🔬" 
  },
  { 
    score: 3000, 
    location: "Early Bitcoin Hub",
    fact: "Hal contributed to Bitcoin's code in this historic spot! 💻" 
  },
  { 
    score: 4000, 
    location: "The Twitter Monument",
    fact: "'Running bitcoin' - Hal's historic tweet was sent from here! 🐦" 
  },
  { 
    score: 5000, 
    location: "Cypherpunk Hall of Fame",
    fact: "Hal's contributions to cryptography are honored here! 🏆" 
  },
];

interface PowerUp {
  type: keyof typeof POWERUP_TYPES;
  x: number;
  collected?: boolean;
}

// Add new interface for active power-ups with timing
interface ActivePowerUp {
  type: keyof typeof POWERUP_TYPES;
  endTime: number;
}

const MilesGame: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [position, setPosition] = useState(0);
  const [obstacles, setObstacles] = useState<number[]>([]);
  const [speed, setSpeed] = useState(INITIAL_SPEED);
  const [difficulty, setDifficulty] = useState(INITIAL_DIFFICULTY);
  const gameLoopRef = useRef<number>();
  const lastSpawnRef = useRef(0);
  const { unlockBadge } = useBadgeStore();
  const [powerUps, setPowerUps] = useState<PowerUp[]>([]);
  const [isInvincible, setIsInvincible] = useState(false);
  const [scoreMultiplier, setScoreMultiplier] = useState(1);
  const [currentFact, setCurrentFact] = useState<string>('');
  const [showFact, setShowFact] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<string>('');
  const [activePowerUps, setActivePowerUps] = useState<ActivePowerUp[]>([]);
  const [isJumping, setIsJumping] = useState(false);

  const handleKeyPress = (e: KeyboardEvent) => {
    if ((e.code === 'Space' || e.code === 'ArrowUp') && !isJumping) {
      setIsJumping(true);
      setPosition(JUMP_HEIGHT);
      setTimeout(() => {
        setPosition(0);
        setIsJumping(false);
      }, JUMP_DURATION);
    }
  };

  const startGame = () => {
    setIsPlaying(true);
    setGameOver(false);
    setScore(0);
    setPosition(0);
    setIsJumping(false);
    setObstacles([]);
    setSpeed(INITIAL_SPEED);
    setDifficulty(INITIAL_DIFFICULTY);
    lastSpawnRef.current = 0;
    unlockBadge('miles_runner');
    setPowerUps([]);
    setIsInvincible(false);
    setScoreMultiplier(1);
    setCurrentFact('');
    setShowFact(false);
  };

  const endGame = () => {
    setIsPlaying(false);
    setGameOver(true);
    setActivePowerUps([]); // Clear active power-ups
    if (score > highScore) {
      setHighScore(score);
      if (score > 1000) {
        unlockBadge('miles_master');
      }
    }
  };

  const shareScore = () => {
    const text = `I just ran ${score} miles with Hal in the Miles with Hal game! Can you beat my score? #HalFinney #Bitcoin`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`, '_blank');
  };

  const handlePowerUp = (type: keyof typeof POWERUP_TYPES) => {
    const now = Date.now();
    const duration = POWERUP_DURATIONS[type];
    const endTime = now + duration;
    
    // Remove existing power-up of the same type before adding new one
    setActivePowerUps(prev => {
      const filtered = prev.filter(p => p.type !== type);
      return [...filtered, { type, endTime }];
    });

    switch (type) {
      case 'BITCOIN':
        setIsInvincible(true);
        setTimeout(() => {
          setIsInvincible(false);
          setActivePowerUps(prev => prev.filter(p => p.type !== 'BITCOIN'));
        }, duration);
        break;
      case 'LIGHTNING':
        setSpeed(prev => prev * 1.5);
        setTimeout(() => {
          setSpeed(INITIAL_SPEED);
          setActivePowerUps(prev => prev.filter(p => p.type !== 'LIGHTNING'));
        }, duration);
        break;
      case 'BLOCK':
        setScoreMultiplier(2);
        setTimeout(() => {
          setScoreMultiplier(1);
          setActivePowerUps(prev => prev.filter(p => p.type !== 'BLOCK'));
        }, duration);
        break;
    }
  };

  const showSightseeingSpot = (location: string, fact: string) => {
    setCurrentLocation(location);
    setCurrentFact(fact);
    setShowFact(true);
    setTimeout(() => setShowFact(false), 3000);
  };

  useEffect(() => {
    if (isPlaying) {
      const handleGameLoop = () => {
        const now = Date.now();
        
        // Increase difficulty based on score
        if (score > 0 && score % SCORE_INCREMENT_THRESHOLD === 0) {
          setSpeed(prev => Math.min(prev + SPEED_INCREMENT, 15));
          setDifficulty(prev => Math.min(prev + DIFFICULTY_INCREMENT, 0.1));
        }

        // Check for sightseeing spots
        const spot = SIGHTSEEING_SPOTS.find(s => s.score === score);
        if (spot) {
          showSightseeingSpot(spot.location, spot.fact);
        }

        // Update obstacles with better spacing
        setObstacles(prev => {
          const newObstacles = prev
            .map(x => x - speed)
            .filter(x => x > -50);

          // Only spawn new obstacle if there's enough space
          const lastObstacle = newObstacles[newObstacles.length - 1] || -Infinity;
          const canSpawnObstacle = lastObstacle <= GAME_WIDTH - MIN_OBSTACLE_SPACING;

          if (canSpawnObstacle && Math.random() < difficulty) {
            newObstacles.push(GAME_WIDTH);
            
            // Spawn power-up with some spacing from obstacle
            if (Math.random() < 0.2) {
              const powerUpType = Object.keys(POWERUP_TYPES)[
                Math.floor(Math.random() * Object.keys(POWERUP_TYPES).length)
              ] as keyof typeof POWERUP_TYPES;
              
              setPowerUps(prev => [...prev, { 
                type: powerUpType, 
                x: GAME_WIDTH + 200 // Add spacing after obstacle
              }]);
            }
          }

          return newObstacles;
        });

        // Update power-ups with proper collision detection
        setPowerUps(prev => {
          const newPowerUps = prev
            .map(p => ({ ...p, x: p.x - speed }))
            .filter(p => p.x > -50 && !p.collected);

          // Check for power-up collection with height check
          newPowerUps.forEach(powerUp => {
            const playerX = 100; // Fixed player x position
            const isAtRightHeight = Math.abs(position - 0) < 30; // Only collect when near ground level
            const isAtRightPosition = Math.abs(powerUp.x - playerX) < COLLISION_THRESHOLD;

            if (!powerUp.collected && isAtRightHeight && isAtRightPosition) {
              powerUp.collected = true;
              handlePowerUp(powerUp.type);
            }
          });

          return newPowerUps;
        });

        // Update score with multiplier
        setScore(prev => prev + (1 * scoreMultiplier));

        // Modified collision check with invincibility
        if (!isInvincible && obstacles.some(obstacleX => {
          const collision = 
            position === 0 &&
            Math.abs(obstacleX - 100) < COLLISION_THRESHOLD;
          return collision;
        })) {
          endGame();
        }

        gameLoopRef.current = requestAnimationFrame(handleGameLoop);
      };

      gameLoopRef.current = requestAnimationFrame(handleGameLoop);
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        if (gameLoopRef.current) {
          cancelAnimationFrame(gameLoopRef.current);
        }
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [isPlaying, obstacles, position, difficulty, speed, score, isInvincible, scoreMultiplier]);

  // Modify the PowerUpTimer component
  const PowerUpTimer: React.FC<{ powerUp: ActivePowerUp }> = ({ powerUp }) => {
    const [timeLeft, setTimeLeft] = useState(POWERUP_DURATIONS[powerUp.type]);

    useEffect(() => {
      // Update more frequently (every 16ms ≈ 60fps)
      const interval = setInterval(() => {
        const remaining = Math.max(0, powerUp.endTime - Date.now());
        setTimeLeft(remaining);

        // Clean up if time is up
        if (remaining <= 0) {
          clearInterval(interval);
        }
      }, 16);

      return () => clearInterval(interval);
    }, [powerUp.endTime]);

    // Calculate progress and seconds with proper rounding
    const progress = Math.max(0, Math.min(100, (timeLeft / POWERUP_DURATIONS[powerUp.type]) * 100));
    const secondsLeft = Math.ceil(timeLeft / 1000);

    // Don't render if time is up
    if (timeLeft <= 0) return null;

    return (
      <div className="flex items-center gap-2 bg-black/80 px-2 py-1 rounded">
        <span className={`${powerUp.type === 'BITCOIN' ? 'text-yellow-500' : 
                           powerUp.type === 'LIGHTNING' ? 'text-blue-500' : 
                           'text-green-500'}`}>
          {POWERUP_TYPES[powerUp.type]}
        </span>
        <div className="w-16 h-2 bg-gray-700/50 rounded-full overflow-hidden">
          <div 
            className={`h-full rounded-full ${
              powerUp.type === 'BITCOIN' ? 'bg-yellow-500' : 
              powerUp.type === 'LIGHTNING' ? 'bg-blue-500' : 
              'bg-green-500'
            }`}
            style={{ 
              width: `${progress}%`,
              transition: 'all 16ms linear'
            }}
          />
        </div>
        <span className={`text-xs min-w-[1.5rem] ${
          secondsLeft <= 2 ? 'text-red-500' : 
          secondsLeft <= 3 ? 'text-yellow-500' : 
          'text-green-500'
        }`}>
          {secondsLeft}s
        </span>
      </div>
    );
  };

  return (
    <div className="terminal-window p-6 rounded-lg">
      <div className="mb-4 flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold terminal-text">Miles with Hal</h2>
          <p className="text-sm opacity-70 terminal-text">Press SPACE or UP ARROW to jump</p>
          <div className="text-xs opacity-70 terminal-text mt-1 flex gap-2">
            <span>₿: Invincibility</span>
            <span>⚡: Speed</span>
            <span>◻️: 2x Score</span>
          </div>
        </div>
        <div className="text-right">
          <p className="terminal-text">Score: {score}</p>
          <p className="terminal-text">High Score: {highScore}</p>
        </div>
      </div>

      <div className="h-48 border-2 border-green-500/30 rounded-lg mb-4 relative overflow-hidden">
        <div className="absolute bottom-0 left-0 w-full h-px bg-green-500/30"></div>
        <div
          className="absolute bottom-4 left-20 transition-transform duration-300 scale-x-[-1]"
          style={{ transform: `translateY(-${position}px) scaleX(-1)` }}
        >
          {PLAYER_CHAR}
        </div>
        {obstacles.map((x, i) => (
          <div
            key={i}
            className="absolute bottom-4 text-xl"
            style={{ left: `${x}px` }}
          >
            {OBSTACLE_CHAR}
          </div>
        ))}
        {powerUps.map((powerUp, i) => (
          !powerUp.collected && (
            <div
              key={`powerup-${i}`}
              className="absolute bottom-4 text-xl"
              style={{ left: `${powerUp.x}px` }}
            >
              {POWERUP_TYPES[powerUp.type]}
            </div>
          )
        ))}
        {showFact && (
          <div className="absolute top-2 left-1/2 transform -translate-x-1/2
                         bg-black/80 p-2 rounded text-green-500 text-sm text-center
                         max-w-[80%] z-10 pointer-events-none flex flex-col gap-1">
            <span className="font-bold text-yellow-500">{currentLocation}</span>
            <span>{currentFact}</span>
          </div>
        )}
        {powerUps.map((powerUp, i) => (
          powerUp.collected && (
            <div
              key={`powerup-notification-${i}`}
              className="absolute top-2 left-1/2 transform -translate-x-1/2
                       bg-black/80 p-2 rounded text-green-500 text-sm text-center
                       max-w-[80%] z-10 pointer-events-none animate-fade-out"
            >
              {POWERUP_DESCRIPTIONS[powerUp.type]}
            </div>
          )
        )).slice(-1)} {/* Only show the most recent notification */}

        {/* Power-up timers */}
        <div className="absolute top-2 right-2 flex flex-col gap-2 z-20">
          {activePowerUps.map((powerUp, index) => (
            <PowerUpTimer key={`${powerUp.type}`} powerUp={powerUp} />
          ))}
        </div>
      </div>

      <div className="flex gap-4">
        {!isPlaying && (
          <button
            onClick={startGame}
            className="retro-button px-4 py-2 rounded terminal-text"
          >
            {gameOver ? 'Try Again' : 'Start Game'}
          </button>
        )}
        {gameOver && (
          <button
            onClick={shareScore}
            className="retro-button px-4 py-2 rounded terminal-text flex items-center gap-2"
          >
            <Share2 size={16} />
            Share Score
          </button>
        )}
      </div>
    </div>
  );
};

export default MilesGame;