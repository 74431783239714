import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Terminal from './components/Terminal';
import Chat from './components/Chat';
import Archives from './components/Archives';
import Transactions from './components/Transactions';
import Challenges from './components/Challenges';
import Resources from './components/Resources';
import Museum from './components/Museum';
import MilesGame from './components/MilesGame';
import CryptoTCG from './components/CryptoTCG';
import ShadowMarket from './components/ShadowMarket';
import BuyABlock from './components/BuyABlock';
import BadgeDisplay from './components/BadgeDisplay';
import BTCTicker from './components/BTCTicker';
import Toast from './components/Toast';
import RunesPromotion from './components/RunesPromotion';
import Footer from './components/Footer';
import { useBadgeStore } from './store/badgeStore';
import SatsTerminal from './components/SatsTerminal';

const AppContent = () => {
  const { unlockBadge, lastUnlockedBadge, clearLastUnlockedBadge } = useBadgeStore();

  React.useEffect(() => {
    unlockBadge('first_visit');
  }, [unlockBadge]);

  return (
    <div className="min-h-screen bg-black text-green-400 font-mono flex flex-col">
      <div className="flex-grow container mx-auto px-4 py-8">
        <header className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-4xl font-bold glitch-text relative">
              <span className="glitch-effect" data-text="HAL FINNEY">HAL FINNEY</span>
            </h1>
            <BTCTicker />
          </div>
          <p className="text-xl opacity-70 terminal-text">&gt; Running Bitcoin since 2009</p>
        </header>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <nav className="lg:col-span-2">
            <Navigation />
          </nav>

          <main className="lg:col-span-10 terminal-window p-6 rounded-lg border border-green-500/30">
            <Routes>
              <Route path="/" element={<Terminal />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/archives" element={<Archives />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/challenges" element={<Challenges />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/museum" element={<Museum />} />
              <Route path="/miles" element={<MilesGame />} />
              <Route path="/tcg" element={<CryptoTCG />} />
              <Route path="/market" element={<ShadowMarket />} />
              <Route path="/blocks" element={<BuyABlock />} />
            </Routes>
          </main>
        </div>

        <BadgeDisplay />
        {lastUnlockedBadge && (
          <Toast
            badge={lastUnlockedBadge}
            onClose={clearLastUnlockedBadge}
          />
        )}
      </div>
      <Footer />
      <RunesPromotion />
      <SatsTerminal />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
      <Analytics />
    </Router>
  );
};

export default App;